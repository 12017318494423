import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const OrganizationModule = namespace('Organization');
let Sider = class Sider extends Vue {
    constructor() {
        super(...arguments);
        this.isShow = false;
        this.openKeys = [];
        this.selectedValue = [];
    }
    get menus() {
        const menus = this.siderMenus;
        this.filterLabelManage(menus);
        return menus;
    }
    created() {
        this.initMenuStatus();
    }
    /**
     * 只有一个组织的时候，只需要显示标签管理
     */
    filterLabelManage(menus) {
        if (this.orgCount > 1)
            return;
        menus.forEach((item) => {
            if (item.code === 'label-manage') {
                const orgSpecialLabel = item.children.find(i => i.code === 'special-use-label');
                item.routeName = orgSpecialLabel?.routeName;
                item.children = [];
            }
        });
    }
    initMenuStatus() {
        this.menus.forEach((m) => {
            if (m.children.length) {
                m.children.forEach((c) => {
                    if (this.$route.name === c.routeName) {
                        this.selectedValue = [c.code];
                        this.openKeys = [m.code];
                    }
                });
            }
            else {
                if (this.$route.name === m.routeName) {
                    this.selectedValue = [m.code];
                }
            }
        });
    }
    goPage(item) {
        if (this.$route.name === item.routeName)
            return;
        this.$router.push({
            name: item.routeName,
        });
    }
};
__decorate([
    OrganizationModule.State('orgCount')
], Sider.prototype, "orgCount", void 0);
__decorate([
    Prop()
], Sider.prototype, "siderMenus", void 0);
Sider = __decorate([
    Component({
        name: 'Sider',
    })
], Sider);
export default Sider;
