import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Sider from '@/components/global/sider/index.vue';
let SystemManage = class SystemManage extends Vue {
    constructor() {
        super(...arguments);
        this.menus = [
            {
                name: '已安装应用',
                code: 'app-installed',
                icon: 'h-icon-all-team-o',
                isShowChild: false,
            },
        ];
    }
};
SystemManage = __decorate([
    Component({
        name: 'SystemManage',
        components: {
            Sider,
        },
    })
], SystemManage);
export default SystemManage;
